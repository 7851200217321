<template>
  <div>
    <v-skeleton-loader
        v-if="loading"
        type="list-item-avatar-three-line"
    ></v-skeleton-loader>
    <div v-else>
      <PlaceHolder
        v-if="details.length === 0"
        :src="require('@/assets/img/svg/no-notifications.svg')"
        title="No writers Found."
        text=""
      />
      <div v-for="(detail, index) in details" :key="index" v-else>
      <v-card flat color="transparent" ripple hover @click="showModal(detail)">
        <v-card-text class="pa-2">
          <writerComponent :detail="detail" />
        </v-card-text>
      </v-card>
    </div>
    <div class="text-center mt-5" v-if="details.length !== 0 && page !== lastPage">
      <v-btn text outlined @click="showMore" class="" large> Show more </v-btn>
    </div>
    </div>
   
    <reviewModal :show="show" :detail="currentDetail" @close="show = false" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import colorMxn from "@/mixins/colorMxn";

export default {
  name: "writerList",
  components: {
    writerComponent: () =>
      import("@/modules/Writers/components/writerComponent"),
    reviewModal: () => import("@/modules/Writers/components/reviewModal"),
    PlaceHolder: () => import('@/components/core/PlaceHolder')
  },
  mixins: [colorMxn],
  data() {
    return {
      show: false,
      currentDetail: null,
      details: [],
      page: 1,
      isOnline: null,
      loading: false,
      total: 0,
      lastPage: 0,
      perPage: 0,
    };
  },
  computed: {
    ...mapGetters(['getIsOnline', 'getSession']),
  },
  watch: {
    getIsOnline(val) {
      this.isOnline = val ? 1 : null;
      this.details = [];
      switch (this.$route.name) {
        case 'allWriters':
          this.getWriters();
          break;
        case 'myWriters':
          this.MyWriters();
          break;
        default:
          this.getWriters();
          break;
    }
    },
  },
  mounted() {
    this.isOnline = this.getIsOnline;
    switch (this.$route.name) {
      case 'allWriters':
        this.getWriters();
        break;
      case 'myWriters':
        this.MyWriters();
        break;
      default:
        this.getWriters();
        break;
    }
  },
  methods: {
    ...mapActions(['performGetActions']),
    async getWriters() {
      this.loading = true;
      const payload = {
        page: this.page,
        isOnline: this.isOnline,
      }
      const fullPayload = {
        params: payload,
        endpoint: '/writers',
      }

      const response = await this.performGetActions(fullPayload);
      this.loading = false;
      this.total = response.data.total;
      this.perPage = response.data.perPage;
      this.lastPage = response.data.lastPage;
      response.data.data.forEach(obj => {
        this.details.push(obj);
      })
    },
    async MyWriters() {
      this.loading = true;
      const payload = {
        page: this.page,
        isOnline: this.isOnline,
        customer_id: this.getSession.customer.id
      }
      
      const fullPayload = {
        params: payload,
        endpoint: '/profile/mywriters'
      }

      const response = await this.performGetActions(fullPayload);
      this.loading = false;
      this.total = response.data.total;
      this.perPage = response.data.perPage;
      this.lastPage = response.data.lastPage;
      response.data.data.forEach(obj => {
        if (obj.writer) {
          this.details.push(obj.writer);
        }
      })
    },
    showMore() {
      this.page = this.page + 1;
      switch (this.$route.name) {
        case 'allWriters':
          this.getWriters();
          break;
        case 'myWriters':
          this.MyWriters();
          break;
        default:
          this.getWriters();
          break;
      }
      
    },
    showModal(obj) {
      this.currentDetail = obj;
      this.show = true;
    }
  }

};
</script>
